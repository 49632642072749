import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`DB Single Leg RDL 4-4-4-4-4/leg`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 EMOM of:`}</p>
    <p>{`5 Burpees`}</p>
    <p>{`15 Squats`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`1500M Row for time.`}</p>
    <p><strong parentName="p">{`*`}{`If you’d like a Murph shirt please write your name and shirt/tank
size on the white board soon.  We’ll need to place the order by the
10th.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      